@import './themes';

@mixin nb-overrides() {
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
    }
  }
  nb-card {
    border-radius: 25px;
  }
  button {
    border-radius: 25px !important;
  }
  nb-card-header {
    padding: 0.5em 2em;
  }
  .nb-theme-corporate {
    border-radius: 25px;
  }
  nb-layout-header.fixed ~ .layout-container {
    padding-top: 1em;
  }
}
